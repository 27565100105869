@font-face {
    font-family: 'Jameel Noori Nastaleeq Regular';
    src: url('../../public/fonts/urdu.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  :root {
    --text-color: #606060;          /* Text color */
    --background-color: #f0f4f9;   /* Background color */
}

  
 .leaflet-interactive{
  stroke: white;
  stroke-width: 1px;
  
  /* fill-opacity: 0.1; */
  /* fill: #ffffff00; */
 } 

 .results{
  background: var(--background-color) !important;
  color: var(--text-color);
 }
 .leaflet-touch .leaflet-geosearch-bar form{
  background: var(--background-color);
 }
 .glass {
  background: var(--background-color);
  color: var(--text-color);
 }

  .leaflet-control-geosearch .results > :hover {
    background-color: gray;
    border-color: #020202;
  }
  .leaflet-touch .leaflet-bar a{
    width: 33px;
    height: 33px;
  }

  .leaflet-bar a{
    border-bottom: none;
   
  }

  .glass::placeholder {
    color: var(--text-color);  
}


  .leaflet-touch .leaflet-bar{
    border: 0px solid rgba(0, 0, 0, 0.2);
  }

  .leaflet-touch .leaflet-bar a:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--background-color);
    color: var(--text-color);
  }

  .leaflet-touch .leaflet-bar a:last-child{
      background: var(--background-color);
      color: var(--text-color);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
  }
  .rdrDefinedRangesWrapper{
    display: none !important;
  }
  .leaflet-left{
    z-index: 1000 !important;
  }

  .MuiTooltip-tooltip {
    padding: 10px !important;
    border: 1px solid;
    color: var(--text-color) !important;
    background-color: var(--background-color) !important;
}