body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --text-color: #606060;          /* Text color */
  --background-color: #f0f4f9;   /* Background color */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-control-attribution,
.leaflet-control-easyPrint {
  display: none !important
}

.map-container {
  filter: blur(1.5px);
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  position: absolute !important;
  bottom: 30% !important;
  left: 0 !important;
  z-index: 1000 !important;
  background-color: white !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.MuiTooltip-tooltip {
  padding: 10px !important;
  border: 1px solid;
  background-color: rgb(26, 26, 26) !important;
}

#dateText {
  font-size: 0.99rem !important
}


::-webkit-scrollbar {
  width: 5px;
  height: 0px
}

::-webkit-scrollbar-track {
  border-radius: 20px
}

::-webkit-scrollbar-thumb {
  background: #28282b;
  border-radius: 20px
}

::-webkit-scrollbar-thumb:hover {
  background: #747474
}

.recharts-default-tooltip {
  margin: 0px !important;
  padding: 20px !important;
  background-color: '#fff' !important;
  color: var(--text-color);
 
   white-space: nowrap !important;
  border-radius: 5px !important;
}

.recharts-tooltip-label {
  margin: 0px !important;
  background-color: '#fff' !important;
  border-radius: 2px !important;
  padding: 10px !important;
  width: 150px !important;
}

.leaflet-geosearch-bar {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.leaflet-touch .leaflet-geosearch-bar form {
  padding: 8px;
  border-radius: 10px;
}

.reset {
  display: none !important;
}

.leaflet-left {
  left: 0% !important;
  z-index: 9999999999 !important;
}

.leaflet-top {
  top: 14% !important;
}

/* Responsive styles for screens less than 900px width */
@media (max-width: 900px) {
  .leaflet-left {
    left: auto !important;
    right: 0% !important;
  }

  .leaflet-top {
    top: 10% !important; /* Adjust this value based on your preference */
  }
}

/* Additional responsive styles for smaller screens if needed */
@media (max-width: 600px) {
  .leaflet-top {
    top: 0% !important; /* Adjust this value based on your preference */
  }
}

.customFab {
  border-radius: 2px !important;
}

.leaflet-pm-toolbar .leaflet-pm-icon-polygon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20' fit='' height='100%' width='100%' preserveAspectRatio='xMidYMid meet' focusable='false'%3E%3Cdefs%3E%3Cpath id='A' d='M43.496 505.323H28.498v-6.656h1.666v3.328h1.667v-3.328h1.666v3.328h1.666v-3.328h1.667v3.328h1.666v-3.328h1.667v3.328h1.666v-3.328h1.667zm0-8.323h-15a1.67 1.67 0 0 0-1.666 1.667v6.666c0 .917.75 1.667 1.667 1.667h15a1.67 1.67 0 0 0 1.666-1.667v-6.666A1.67 1.67 0 0 0 43.497 497z'%3E%3C/path%3E%3C/defs%3E%3Cuse xlink:href='%23A' transform='rotate(315 -570.897 287.385)'%3E%3C/use%3E%3C/svg%3E") !important;

}

.button-container {
  /* background-color: '#28282b' !important;
  filter: invert(100%) brightness(1000%) !important;   */
}

.leaflet-pm-icon-delete {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='60%' height='60%' viewBox='0 0 12 12' fit='' preserveAspectRatio='xMidYMid meet' focusable='false'%3E%3Cdefs%3E%3Cpath id='flyca' d='M1847.837 163.345l-1.175-1.175-4.659 4.658-4.658-4.658-1.175 1.175 4.658 4.658-4.658 4.659 1.175 1.175 4.658-4.659 4.659 4.659 1.175-1.175-4.659-4.659z'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cg transform='translate(-1836 -162)'%3E%3Cuse xlink:href='%23flyca'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  width: 75% !important;
  height: 100% !important;
  margin: auto !important;
}

.cwr-container {
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  /* transform: translateY(100%); */
}

.cwr-container .slide-up {
  transform: translateY(0);
}

.cwr-container .slide-down {
  transform: translateY(100%);
}